







import { Component, Vue } from 'vue-property-decorator'
import SidebarSwitcher from '@/components/organisms/v2/SidebarSwitcher.vue'
import Goals from '@/components/organisms/v3/dashboard/TopGoal.vue'
@Component({
  components: {
    SidebarSwitcher,
    Goals,
  },
})
export default class GoalList extends Vue {
}
